import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Food Delivery App Template | Food Order App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/mobile-food-delivery-app/"
    metaDescription="If you're looking to design a food takeaway app for your restaurant or a food delivery service for your area, look no further. Customize now."
    description="
    h2:Introducing QuickBite! Our mobile food delivery app design template
    <br/>
    With QuickBite, a food ordering mobile app template, designing your FoodTech business is now easier than ever before. Uizard is built for rapid design, enabling you to craft <a:https://uizard.io/prototyping/>mobile app prototypes</a> at lightning speed, and our food app template is no exception!
    <br/>
    h3:An easy-to-use food delivery app template for your own project
    <br/>
    If you're looking to design a food takeaway app for your restaurant, look no further than QuickBite, Uizard's latest food ordering app template. This template comes with 13 mobile screens that are beautifully designed with easy customization possibilities in mind. As with all our <a:https://uizard.io/templates/mobile-app-templates/>mobile app design templates</a>, when you choose this food delivery app template, you can replace all text, images, colors, and styles with your own. It's the perfect head start for a personalized food delivery app for your FoodTech startup!
    <br/>
    h3:QuickBite! includes all the basics and more
    <br/>
    QuickBite! enables you to design a companion app for your web-based food delivery business with minimal time and effort. Not only are the basic screens for a food ordering app included, but you also get extra screens that truly take your food business to the next level.
    "
    pages={[
      "A home page",
      "A login page",
      "A 'discover restaurants' page",
      "A Search page",
      "An order cart",
      "An entire checkout / purchase order flow",
      "And more!",
    ]}
    projectCode="wnpmLree5lUYV1nMEYgd"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Quick Bite Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Quick Bite Mobile App: restaurants search"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Quick Bite Mobile App: restaurant page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Quick Bite Mobile App: checkout"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Quick Bite Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/Quick-Bite-mobile-app/Quick-Bite-Mobile-Cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/Quick-Bite-mobile-app/Quick-Bite-Mobile-Restaurants.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/Quick-Bite-mobile-app/Quick-Bite-Mobile-Restaurant-Page.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/Quick-Bite-mobile-app/Quick-Bite-Mobile-Checkout.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/Quick-Bite-mobile-app/Quick-Bite-Mobile-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
